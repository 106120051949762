/* eslint-disable @typescript-eslint/promise-function-async */
import canSee from "@/helpers/can-see";
import getUserClass from "@/helpers/get-user-class";
import { lazy } from "react";
import HetReports from "./views/hetReports";

const Requests = lazy(() => import("./views/hetReports"));

const hetReportsRoute = canSee(
  {
    id: "het-reports",
    title: "het-reports",
    path: "het-reports",
    element: <HetReports />,
  },
  getUserClass().role,
);

export default hetReportsRoute;
