import { useNavigate, useParams } from "react-router-dom";
import { Typography, message, theme } from "antd";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import queryClient from "@/utils/query-client";
import MessageContent from "@/components/message-content";
import { useTranslation } from "react-i18next";
import type { AxiosResponse } from "axios";
import {
  // getInspectorStatuses,
  getInspectorActDetails,
  getInspectorActReasons,
  // updateInspectorActStatus,
  updateStatusCancel,
  updateStatusAccept,
  updateStatusReject,
} from "../../api";
import type {
  // ActStatusKey,
  DetailsState,
} from "../../types";

const { Title } = Typography;

export default function useDetailsState(): DetailsState {
  const [reason, setReason] = useState<number>();
  const [note, setNote] = useState<string>();
  const [openModalImage, setOpenModalImage] = useState<string | null>(null);
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isOpenMapModal, setIsOpenMapModal] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { id: formId } = useParams();

  // const { data: inspectorStatuses } = useQuery({
  //   queryKey: ["inspector-statuses"],
  //   queryFn: getInspectorStatuses,
  //   select(data) {
  //     return data.results;
  //   },
  // });

  const { data: formDetails, isLoading: isFormDetailsLoading, refetch } = useQuery({
    queryKey: ["tablet-form-details", formId],
    queryFn: async () => {
      const res = await getInspectorActDetails(formId);
      return res;
    },
    enabled: !!formId,
  });

  const { data: reasonsData } = useQuery({
    queryKey: ["tablet-forms-reasons"],
    queryFn: async () => {
      const res = await getInspectorActReasons();
      return res;
    },
  });

  const reasons =
    reasonsData?.map((r) => ({
      label: r.name,
      value: r.id,
    })) ?? [];

  const cancelMut = useMutation({
    mutationFn: async () => {
      const res = await updateStatusCancel(formId, {
        reason,
        description: note,
      });

      return res;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["tablet-forms"]);
      void queryClient.invalidateQueries(["tablet-form-details", formId]);
      void messageApi.open({
        key: "custom",
        type: "success",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("custom");
            }}
          >
            {t("success")}
          </MessageContent>
        ),
        duration: 2.5,
      });
      setIsReturnModalOpen(false);
      setIsCancelModalOpen(false);
    },
    onError: (res: AxiosResponse) => {
      void messageApi.open({
        key: "custom",
        type: "error",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("custom");
            }}
          >
            {res.data?.reason?.[0]}
          </MessageContent>
        ),
        duration: 2.5,
      });
    },
  });

  const rejectMut = useMutation({
    mutationFn: async () => {
      await updateStatusReject(formId);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["tablet-forms"]);
      void queryClient.invalidateQueries(["tablet-form-details", formId]);
      refetch();
      void messageApi.open({
        key: "custom",
        type: "success",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("custom");
            }}
          >
            {t("success")}
          </MessageContent>
        ),
        duration: 2.5,
      });
      setIsReturnModalOpen(false);
      setIsCancelModalOpen(false);
    },
    onError: () => {
      void messageApi.open({
        key: "custom",
        type: "error",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("custom");
            }}
          >
            {}
          </MessageContent>
        ),
        duration: 2.5,
      });
    },
  });

  const acceptMut = useMutation({
    mutationFn: async () => {
      await updateStatusAccept(formId);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["tablet-forms"]);
      queryClient.invalidateQueries(["tablet-form-details", formId]);
      void messageApi.open({
        key: "custom",
        type: "success",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("custom");
            }}
          >
            {t("success")}
          </MessageContent>
        ),
        duration: 2.5,
      });
      setIsReturnModalOpen(false);
      setIsCancelModalOpen(false);
    },
    onError: () => {
      void messageApi.open({
        key: "custom",
        type: "error",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("custom");
            }}
          >
            {}
          </MessageContent>
        ),
        duration: 2.5,
      });
    },
  });

  // const statusMut = useMutation({
  //   mutationFn: async (payload: { id: number; newStatus: ActStatusKey }) => {
  //     const status = inspectorStatuses?.find(
  //       (s) => s.key === payload.newStatus,
  //     )?.id;

  //     if (typeof status === "number") {
  //       await updateInspectorActStatus(payload.id, {
  //         status_id: status,
  //         reason,
  //         description: note,
  //       });
  //     }
  //   },
  //   onSuccess: () => {
  //     void queryClient.invalidateQueries(["tablet-forms"]);
  //     void messageApi.open({
  //       key: "custom",
  //       type: "success",
  //       content: (
  //         <MessageContent
  //           destroy={() => {
  //             messageApi.destroy("custom");
  //           }}
  //         >
  //           {t("success")}
  //         </MessageContent>
  //       ),
  //       duration: 2.5,
  //     });
  //     setIsReturnModalOpen(false);
  //     setIsCancelModalOpen(false);
  //   },
  //   onError: () => {
  //     void messageApi.open({
  //       key: "custom",
  //       type: "error",
  //       content: (
  //         <MessageContent
  //           destroy={() => {
  //             messageApi.destroy("custom");
  //           }}
  //         >
  //           {}
  //         </MessageContent>
  //       ),
  //       duration: 2.5,
  //     });
  //   },
  // });

  const onNullify = (): void => {
    rejectMut.mutate();
  };

  const onReturn = (): void => {
    cancelMut.mutate();

    setTimeout(() => {
      setReason(undefined);
      setNote(undefined);
    }, 400);
  };

  const onReceive = (): void => {
    acceptMut.mutate();
  };

  console.log('acceptMut', acceptMut.isLoading);
  

  const onReturnModalCancel = (): void => {
    setIsReturnModalOpen(false);
  };

  const onCancelModalCancel = (): void => {
    setIsCancelModalOpen(false);
  };

  const onCloseMapModal = (): void => {
    setIsOpenMapModal(false);
  };

  const goBack = (): void => {
    navigate(-1);
  };

  return {
    contextHolder,
    onNullify,
    onReceive,
    onReturn,
    formDetails,
    isFormDetailsLoading,
    setIsReturnModalOpen,
    setIsCancelModalOpen,
    isReturnModalOpen,
    onReturnModalCancel,
    isCancelModalOpen,
    onCancelModalCancel,
    note,
    setNote,
    reason,
    setReason,
    Title,
    t,
    goBack,
    colorBgContainer,
    reasons,
    onCloseMapModal,
    isOpenMapModal,
    setIsOpenMapModal,
    openModalImage,
    setOpenModalImage,
    acceptLoader: acceptMut.isLoading,
  };
}
