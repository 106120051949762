import CustomModal from "@/components/modal";
import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function PinnedRows({ item }: any): JSX.Element {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const detectFileType = (file: string) => {
    const fileExtension = file.split(".").pop();
    if (fileExtension === "pdf") {
      return "pdf";
    }
    if (
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "png"
    ) {
      return "img";
    }
    if (fileExtension === "mp4") {
      return "video";
    }

    return "pdf";
  };

  return (
    <>
      <Button
        type="link"
        className="!underline font-normal text-sm p-0"
        onClick={() => {
          if (detectFileType(item.file) === "pdf") {
            window.open(item.file, "_blank");
            return;
          }
          setIsOpenModal(true);
        }}
      >
        {detectFileType(item.file) === "pdf" ? t("download-pdf") : t("see")} (
        {item.file.split(".").pop()})
      </Button>

      <CustomModal
        title={<div className="flex flex-col items-center gap-2">Файл</div>}
        open={isOpenModal}
        footer={null}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        centered
        width={400}
      >
        <div className="flex flex-col items-center gap-3">
          {detectFileType(item.file) === "pdf" ? (
            <>
              {t("You can see the file downloaded")}
              <Button
                type="primary"
                size="large"
                className="w-[140px]"
                onClick={() => {
                  window.open(item.file, "_blank");
                }}
              >
                {t("see")}
              </Button>
            </>
          ) : detectFileType(item?.file) === "video" ? (
            <video
              src={item?.file}
              controls
              className="w-[300px] h-[200px]"
            ></video>
          ) : (
            <div className="flex justify-center items-center h-[500px] w-full">
              <img src={item?.file} alt="file" className="w-full h-full object-contain"/>
            </div>
          )}
        </div>

        <br />
        <div className="flex justify-center gap-4">
          <Button
            type="primary"
            size="large"
            className="bg-[#D8F3DC] text-[#40916C] w-[140px]"
            onClick={() => {
              setIsOpenModal(false);
            }}
          >
            {t("close")}
          </Button>
        </div>
      </CustomModal>
    </>
  );
}
