/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/promise-function-async */
// import canSee from "@/helpers/can-see";
import { lazy } from "react";

import DashboardMain from "./views/dashboard-main";

const MonitorIcon = lazy(() => import("./components/monitor-icon"));

const dashboarRoutes = {
  id: "dashboard",
  title: "dashboard",
  path: "dashboard",
  element: <DashboardMain />,
  Icon: MonitorIcon,
  errorElement: {},
  children: [],
};

export default dashboarRoutes;
