import ExcelIcon from "@/features/reports/components/excel-icon";
import { Button, Flex, Typography } from "antd";
import { useState } from "react";
import ReportModalOnlyOrgan from "./report-moda-only-organ";
import ReportModal from "./report-modal";
import ReportModalWithRange from "./report-modal-with-range";
import ReportModalYearOrgan from "./report-modal-year-organ";

export default function ReportItem({ item, index }: any): JSX.Element {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setOpenModal(true);
        }}
        className="bg-[#FAFBFC] border-[#F5F5F5] shadow-none h-auto min-h-[110px]"
      >
        <Flex className="p-[20px] flex gap-4 items-start w-full justify-start">
          <ExcelIcon />
          <Typography.Text className="text-[#081C15] text-lg font-medium text-wrap text-start">
            {index + 1}. {item?.title}
          </Typography.Text>
        </Flex>
      </Button>

      {item?.type === "with-range" && (
        <ReportModalWithRange
          openModalWithRange={openModal}
          item={item}
          onCancel={() => {
            setOpenModal(false);
          }}
        />
      )}

      {item?.type === "report-modal" && (
        <ReportModal
          openModal={openModal}
          item={item}
          onCancel={() => {
            setOpenModal(false);
          }}
        />
      )}

      {item?.type === "only-organ" && (
        <ReportModalOnlyOrgan
          openModal={openModal}
          item={item}
          onCancel={() => {
            setOpenModal(false);
          }}
        />
      )}

      {item?.type === "year-organ" && (
        <ReportModalYearOrgan
          openModalWithRange={openModal}
          item={item}
          onCancel={() => {
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
}
