import TableEmpty from "@/components/table-empty";
import { ConfigProvider, Flex } from "antd";
// import AddIcon from "../../components/add-icon";
import ReportItem from "@/components/report-item";
import { useState } from "react";
import useInspectorsState from "./state";

export default function HgtReports(): React.ReactElement {
  const {
    Header,
    Content,
    data,
    columns,
    colorBgContainer,
    paginationProps,
    isTableLoading,
    contextHolder,
    onPageChange,
    onAddClick,
    statusCount,
    onSearchChange,
    t,
    setStatus,
  } = useInspectorsState();

  const items = [
    {
      title: "Далолатномалар Фаолияти бўйича",
      type: "only-organ",
      key: "first",
      link: "/violations/report/excel/activity",
    },
    {
      title: "Жами далолатномалар",
      type: "with-range",
      key: "second",
      link: "/violations/report/excel/all",
    },
    {
      title: "Солиштирма далолатномалар",
      type: "only-organ",
      key: "third",
      link: "/violations/report/excel/comparison",
    },
    {
      title: "Далолатномалар ойлар кесимида",
      type: "year-organ",
      key: "fourth",
      link: "/violations/report/excel/monthly",
    },
    {
      title: "Далолатномалар ойлик ҳисоботи",
      type: "report-modal",
      key: "fifth",
      link: "/violations/report/excel/summary",
    },
    {
      title: "Хукукбузарлик ҳолати бўйича далолатномалар",
      type: "only-organ",
      key: "sixth",
      link: "/violations/report/excel/violation",
    },
  ];

  return (
    <ConfigProvider
      renderEmpty={() => (
        <TableEmpty
          title={t("inspectors-list-empty")}
          description={t("inspectors-list-empty-info")}
        />
      )}
    >
      {contextHolder}
      <Header
        style={{ background: colorBgContainer }}
        className="px-8 pt-2 flex justify-start items-center"
      >
        <h1 className="font-semibold text-xl">{t('hgt-reports')}</h1>
      </Header>

      <Content
        className="p-8"
        style={{
          overflow: "initial",
          background: colorBgContainer,
        }}
      >
        <Flex className="grid grid-cols-3" gap={24}>
          {items?.map((item, index) => (
            <ReportItem key={item?.key} item={item} index={index} />
          ))}
        </Flex>
      </Content>
    </ConfigProvider>
  );
}
