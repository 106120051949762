import type { CacheQuery } from "@/types";
import { getRegions } from "../api";

export default function regionsQuery(): CacheQuery<
  Awaited<ReturnType<typeof getRegions>>
> {
  return {
    queryKey: ["regions"],
    queryFn: async () => {
      const res = await getRegions();
      return res;
    },
  };
}
