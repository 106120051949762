/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import getOrFetch from "@/helpers/get-or-fetch";
import canSee from "@/helpers/can-see";
import getUserClass from "@/helpers/get-user-class";
import { offenseQuery, statusesQuery } from "./queries";
import Container from "./views/container";
import Offense from "./views/offense";

const RadarIcon = lazy(() => import("./components/radar-icon"));
const Offenses = lazy(() => import("./views"));

const violationRoutes = canSee(
  {
    id: "offenses",
    title: "uzenergo-ilovadan",
    path: "offenses",
    Icon: RadarIcon,
    element: <Container of={<Offenses />} />,
    loader: async () => {
      const statuses = await getOrFetch(statusesQuery);
      return statuses;
    },
    children: [
      {
        id: "offense",
        title: "Offense",
        path: ":offenseId",
        element: <Offense />,
        loader: async ({ params }) => {
          const [offense] = await Promise.all([
            getOrFetch(() => offenseQuery(params.offenseId as number)),
          ]);

          if (offense === null) {
            // replace to any unspecified route to trigger catch all route
            window.location.replace("/not-found");
            return null;
          }

          return [offense];
        },
      },
    ],
  },
  getUserClass().role,
);

export default violationRoutes;
