/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { violationTypesQuery } from "@/features/acts-list/queries";
import { useQuery } from "@tanstack/react-query";
import { DatePicker, Flex, Select } from "antd";
import Layout from "antd/es/layout/layout";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import regionsQuery from "../queries";
import { log } from "console";
import dayjs from "dayjs";

const Header = Layout;
const { RangePicker } = DatePicker;

export default function HeaderDashboard({
  filters,
  setFilters,
}: any): JSX.Element {
  const { search } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: regions, isLoading: regionsLoader } = useQuery({
    ...regionsQuery(),
    placeholderData: { count: 0, next: null, previous: null, results: [] },
  });

  const { data: violationTypesList, isLoading: violationTypesLoader } =
    useQuery({
      ...violationTypesQuery(),
      placeholderData: { count: 0, next: null, previous: null, results: [] },
    });

  return (
    <Header className="px-8 bg-white w-full py-4 h-[72px]">
      <Flex className="flex items-center justify-between" gap={16}>
        <Select
          size="large"
          placeholder={t("region")}
          className="w-full"
          loading={regionsLoader}
          allowClear
          value={filters?.region || undefined}
          onChange={(value) => {
            setFilters({ ...filters, region: value, district: undefined });
            navigate(
              `?${queryString.stringify({
                ...queryString.parse(search),
                region_id: value,
                district_id: undefined,
              })}`,
            );
          }}
          options={regions?.results.map((region) => ({
            label: region.name,
            value: region.id,
          }))}
        />

        <Select
          size="large"
          placeholder={t("district")}
          className="w-full"
          loading={regionsLoader}
          allowClear
          value={filters?.district || undefined}
          onChange={(value) => {
            setFilters({ ...filters, district: value });
            navigate(
              `?${queryString.stringify({
                ...queryString.parse(search),
                district_id: value,
              })}`,
            );
          }}
          options={regions?.results
            .find((region) => region.id === filters.region)
            ?.districts.map((district) => ({
              label: district.name,
              value: district.id,
            }))}
        />
        <Select
          size="large"
          placeholder={t("organization")}
          className="w-full"
          allowClear
          loading={violationTypesLoader}
          value={filters?.violation_type || undefined}
          onChange={(value) => {
            setFilters({ ...filters, violation_type: value });
            navigate(
              `?${queryString.stringify({
                ...queryString.parse(search),
                violation_type: value,
              })}`,
            );
          }}
          options={violationTypesList?.results.map((type) => ({
            label: type.name,
            value: type.key,
          }))}
        />
        <RangePicker
          className="w-full"
          size="large"
          placeholder={[t("from"), t("to")]}
          value={
            filters.min_date && filters.max_date
              ? [
                  dayjs(filters.min_date, "YYYY-MM-DD"),
                  dayjs(filters.max_date, "YYYY-MM-DD"),
                ]
              : undefined
          }
          format="YYYY-MM-DD"
          onChange={(value) => {
            if (value === null) {
              setFilters({
                ...filters,
                min_date: undefined,
                max_date: undefined,
              });
              navigate(
                `?${queryString.stringify({
                  ...queryString.parse(search),
                  min_date: undefined,
                  max_date: undefined,
                })}`,
              );
              return;
            }
            setFilters({
              ...filters,
              min_date: value && dayjs(value[0]).format("YYYY-MM-DD"),
              max_date: value && dayjs(value[1]).format("YYYY-MM-DD"),
            });
            navigate(
              `?${queryString.stringify({
                ...queryString.parse(search),
                min_date: value && dayjs(value[0]).format("YYYY-MM-DD"),
                max_date: value && dayjs(value[1]).format("YYYY-MM-DD"),
              })}`,
            );
          }}
        />
      </Flex>
    </Header>
  );
}
