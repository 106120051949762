import request from "@/utils/axios";
import type { Group } from "../types";

export async function getGroups(): Promise<Group[]> {
  const res: Group[] = await request({
    url: "/account/users/groups/",
    method: "get",
  });
  return res;
}

export async function addToGroup(
  id: string | number,
  data: { group: number },
): Promise<void> {
  await request({
    url: `/account/users/${id}/add_to_group/`,
    method: "post",
    data,
  });
}

export async function getStatusCountListInspectors({search}: any): Promise<any> {
  const result: any = await request({
    url: "/account/users/inspectors-count/",
    method: "get",
    params: { search },
  });

  return result;
}
