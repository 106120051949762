import { useLangContext } from "@/contexts";
import { useTranslation } from "react-i18next";

interface Props {
  total: number;
  range: [number, number];
  text: string;
}

export default function ShowTotal(props: Props): React.ReactElement {
  const { range, total, text } = props;

  const { t } = useTranslation();

  const { lang } = useLangContext();

  return (
    <span className="text-[#8498B4]">
      {lang === "ru"
        ? `${t(text ?? "")} ${range[0]}-${range[1]} ${t("out-of")} ${total}`
        : `${t(text ?? "")} ${range[0]}-${range[1]}, ${total} ${t("out-of")}`}
    </span>
  );
}
