/* eslint-disable @typescript-eslint/promise-function-async */
import canSee from "@/helpers/can-see";
import getUserClass from "@/helpers/get-user-class";
import { sift } from "radash";
import { lazy } from "react";
import hetReportsRoute from "../HETReports/routes";
import HgtReports from "./views/reports";

const Container = lazy(() => import("./views/container"));
const ReportsIcon = lazy(() => import("./components/report-icon"));

const reportsRoutes = canSee(
  {
    id: "reports",
    title: "reports",
    path: "reports",
    Icon: ReportsIcon,
    children: sift([
      {
        id: "hgt-reports",
        title: "hgt-reports",
        path: "hgt-reports",
        element: <HgtReports />,
      },
      hetReportsRoute,
    ]),
  },
  getUserClass().role,
);

export default reportsRoutes;
