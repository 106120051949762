import { useTranslation } from "react-i18next";
import { Divider } from "antd";

interface Props {
  styles?: "inherit" | "default";
}

export default function DeveloperNotice(props: Props): React.ReactElement {
  const { styles } = props;

  const { t } = useTranslation();

  if (styles === "inherit") {
    return <p className="text-center">{t("designed-by-uzinfocom")}</p>;
  }

  return (
    <div className="bg-white">
      {/* <Divider className="my-2" /> */}
      <p className="text-center mb-2">{t("designed-by-uzinfocom")}</p>
    </div>
  );
}

DeveloperNotice.defaultProps = {
  styles: "default",
};
