import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

export default function ReturnedWorks({ data }: any): JSX.Element {
  const { t } = useTranslation();

  const mvdDonutChart = {
    chart: {
      type: "pie",
      height: "100%",
    },
    labels: [...(data?.mvd?.map((item: any) => item.name) ?? [])],
    series: [...(data?.mvd?.map((item: any) => item.value) ?? [])],
    colors: ["#37CD7B", "#FF5858", "#FFE071"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val: any) {
          return val.toLocaleString();
        },
      },
    },
    responsive: [
      {
        options: {
          chart: {
            width: 100,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "70%",
          labels: {
            show: true,
            total: {
              show: true,
              label: "Total",
              // formatter: function () {
              //   return 20000;
              // },
            },
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "14px",
              color: undefined,
              offsetY: 5,
              formatter: function (val: any) {
                return val.toLocaleString();
              },
            },
          },
        },
      },
    },
  };

  const procuraturaDonutChart = {
    chart: {
      type: "pie",
      height: "100%",
    },
    labels: [...(data?.prokuratura?.map((item: any) => item.name) ?? [])],
    series: [...(data?.prokuratura?.map((item: any) => item.value) ?? [])],
    colors: ["#37CD7B", "#FF5858", "#FFE071"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val: any) {
          return val.toLocaleString();
        },
      },
    },
    responsive: [
      {
        options: {
          chart: {
            width: 100,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "70%",
          labels: {
            show: true,
            total: {
              show: true,
              label: "Total",
              // formatter: function () {
              //   return 20000;
              // },
            },
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "14px",
              color: undefined,
              offsetY: 5,
              formatter: function (val: any) {
                return val.toLocaleString();
              },
            },
          },
        },
      },
    },
  };

  return (
    <div className="flex flex-col w-full p-4">
      <div className="flex items-center justify-center mb-[12px] w-full">
        <h4 className="text-left text-[14px] font-semibold font-Golos text-[#232E3A]">
          {t("returned-works")}
        </h4>
      </div>

      <div className="flex items-center">
        <ReactApexChart
          options={
            {
              ...mvdDonutChart,
              labels: mvdDonutChart.labels,
            } as any
          }
          series={mvdDonutChart.series}
          type="donut"
          width="130"
          height="65%"
        />

        <div className="flex flex-col gap-[6px] w-full">
          {data?.mvd?.map((item: any, index: number) => (
            <div className="flex items-center justify-between" key={item?.name}>
              <div className="flex items-center gap-2">
                <span
                  style={{
                    borderColor: ["#37CD7B", "#FF5858", "#FFE071"]?.[index],
                  }}
                  className="inline-block w-[8px] h-[8px] border-2 rounded-full "
                />
                <p className="text-xs font-normal text-[#232E3A]">
                  {item?.name}
                </p>
              </div>
              <p className="text-xs font-medium text-[#9747FF]">
                {item?.value}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center">
        <ReactApexChart
          options={
            {
              ...procuraturaDonutChart,
              labels: procuraturaDonutChart.labels,
            } as any
          }
          series={procuraturaDonutChart.series}
          type="donut"
          width="130"
          height="65%"
        />

        <div className="flex flex-col gap-[6px] w-full">
          {data?.prokuratura?.map((item: any, index: number) => (
            <div className="flex items-center justify-between" key={item?.name}>
              <div className="flex items-center gap-2">
                <span
                  style={{
                    borderColor: ["#37CD7B", "#FF5858", "#FFE071"]?.[index],
                  }}
                  className="inline-block w-[8px] h-[8px] border-2 rounded-full "
                />
                <p className="text-xs font-normal text-[#232E3A]">
                  {item?.name}
                </p>
              </div>
              <p className="text-xs font-medium text-[#9747FF]">
                {item?.value}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
