import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

export default function SentActQuentity({ data }: any): JSX.Element {
  const { t } = useTranslation();

  const procuraturaDonutChart = {
    chart: {
      type: "pie",
      height: "100%",
    },
    labels: [...(data?.map((item: any) => item.name) ?? [])],
    series: [...(data?.map((item: any) => item.value) ?? [])],
    colors: ["#37CD7B", "#FF5858", "#FFE071"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val: any) {
          return val.toLocaleString();
        },
      },
    },
    responsive: [
      {
        options: {
          chart: {
            width: 100,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "70%",
          labels: {
            show: true,
            total: {
              show: true,
              label: "Total",
            },
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "14px",
              color: undefined,
              offsetY: 5,
              formatter: function (val: any) {
                return val.toLocaleString();
              },
            },
          },
        },
      },
    },
  };

  return (
    <div className="flex flex-col p-4 items-center h-full overflow-auto">
      <div className="flex items-center justify-center mb-[12px] h-[22px]">
        <h4 className="text-left text-[14px] font-semibold font-Golos text-[#232E3A]">
          {t("col1111")}
        </h4>
      </div>

      <div className="h-full">
        <ReactApexChart
          options={
            {
              ...procuraturaDonutChart,
              labels: procuraturaDonutChart.labels,
            } as any
          }
          series={procuraturaDonutChart.series}
          type="donut"
          height="70%"
          width="200px"
          style={{
            minHeight: "auto",
          }}
        />
      </div>

      <div className="flex flex-col gap-[6px] w-full h-[60px]">
        {data?.map((item: any, index: any) => (
          <div key={item?.name} className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <span
                style={{
                  borderColor: ["#37CD7B", "#FF5858", "#FFE071"]?.[index],
                }}
                className="inline-block w-[8px] h-[8px] border-2 rounded-full "
              />
              <p className="text-xs font-normal text-[#232E3A]">
                {t(item?.name)}
              </p>
            </div>
            <p className="text-xs font-medium text-[#9747FF]">{item?.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
