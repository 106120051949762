import useUserClass from "@/hooks/use-user-class";

interface Props {
  I: string;
  not?: boolean;
  children: React.ReactElement;
}

export default function Can(props: Props): React.ReactElement | null {
  const { I, not, children } = props;

  const { group } = useUserClass();

  const permissions = group?.permissions ?? [];

  const permission = permissions.find(({ codename }) => codename === I);

  if (typeof permission !== "undefined" && not === false) {
    return children;
  }

  return null;
}

Can.defaultProps = {
  not: false,
};
