import formatDate from "@/helpers/format-date";
import { fetchBlob } from "@/utils/axiosWithoutData";
import { Button, DatePicker, Divider, Flex, Select, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomModal from "./modal";

export default function ReportModal({
  openModal,
  onCancel,
  item,
}: any): JSX.Element {
  const [filter, setFilter] = useState<any>({});
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onDownload = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const { blob, headers } = await fetchBlob(item?.link, {
        ...filter,
        date: formatDate(filter?.date, "YYYY-MM-DD"),
        year: formatDate(filter?.year, "YYYY"),
        type: pathname === "/reports/het-reports" ? "het" : "hgt",
      });

      const contentDisposition = headers["content-disposition"];
      let filename = `report-${formatDate(new Date(), "YYYY-MM-DD")}.xlsx`;

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(
          /filename="?(.+?)"?(;|$)/,
        );
        if (filenameMatch != null && filenameMatch[1]) {
          filename = filenameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!openModal) {
      setFilter({});
    }
  }, [openModal]);

  return (
    <CustomModal
      open={openModal}
      onCancel={onCancel}
      width={700}
      footer={
        <>
          <Divider className="mb-3" />
          <Flex className="items-center justify-end" gap={12}>
            <Button onClick={onCancel}>{t("cancel")}</Button>
            <Button
              loading={isLoading}
              onClick={onDownload}
              className="bg-[#40916C] text-white"
            >
              {t("download-doc")}
            </Button>
          </Flex>
        </>
      }
    >
      <Flex className="pt-[12px]" vertical>
        <Typography.Text className="text-lg font-medium text-wrap text-center mb-[24px]">
          {item?.title}
        </Typography.Text>

        <Flex className="gap-2 grid grid-cols-[1fr_0.3fr_0.3fr_0.3fr]">
          <Select
            id="sdf"
            placeholder={t("organization")}
            className="w-full"
            size="large"
            value={filter?.org}
            options={[
              { label: "HET", value: "1" },
              { label: "HGT", value: "2" },
              { label: "UZI", value: "3" },
            ]}
            onChange={(value) => {
              setFilter({ ...filter, org: value });
            }}
            allowClear
          />

          <DatePicker
            value={filter?.year}
            placeholder={t("year")}
            onChange={(e) => {
              setFilter({ ...filter, year: e });
            }}
            picker="year"
          />

          <Select
            id="sdf"
            placeholder={t("month")}
            className="w-full"
            size="large"
            value={filter?.month}
            onChange={(value) => {
              setFilter({ ...filter, month: value });
            }}
            options={[
              { label: "Январь", value: "1" },
              { label: "Февраль", value: "2" },
              { label: "Март", value: "3" },
              { label: "Апрель", value: "4" },
              { label: "Май", value: "5" },
              { label: "Июнь", value: "6" },
              { label: "Июль", value: "7" },
              { label: "Август", value: "8" },
              { label: "Сентябрь", value: "9" },
              { label: "Октябрь", value: "10" },
              { label: "Ноябрь", value: "11" },
              { label: "Декабрь", value: "12" },
            ]}
            allowClear
          />

          <DatePicker
            size="large"
            className="w-full"
            placeholder={t("select-date")}
            value={filter?.date}
            disabledDate={(current) =>
              current && current > dayjs().endOf("day")
            }
            onChange={(date) => {
              setFilter({ ...filter, date: date });
            }}
          />
        </Flex>
      </Flex>
    </CustomModal>
  );
}
