import formatAmount from "@/helpers/format-amount";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

export default function DistributedSubmittedWorks({ data }: any): JSX.Element {
  const { t } = useTranslation();

  const sentWorks = useMemo(
    () => ({
      series: [
        {
          data: [data?.count_mvd_to_procroture],
          name: t("sentFromMvdToProc"),
          color: "#06D188",
        },
        {
          data: [data?.procrature_to_mvd],
          name: t("sentFromProcToMvd"),
          color: "#9747FF",
        },
      ],
      chart: {
        type: "bar",
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          barHeight: "80%",
          horizontal: true,
          columnWidth: "40%",
          dataLabels: {
            show: false,
          },
          borderRadiusApplication: "end",
          colors: {
            backgroundBarColors: ["#315EFB1A"],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 5,
          },
        },
      },
      stroke: {
        colors: ["transparent"],
        width: 2,
      },
      dataLabels: {
        enabled: true,
        textAnchor: "none",
        style: {
          colors: ["#000"],
          fontWeight: "bold",
        },
        formatter: (val: number) => ` ${val !== 0 ? formatAmount(val) : ""}`,
        offsetX: 20,
        offsetY: 0,
      },
      xaxis: {
        max: 1000,
        min: 0,
        labels: {
          show: false,
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
          align: "left",
          style: {
            colors: ["#262626"],
            fontSize: "12px",
            fontWeight: 500,
          },
          offsetY: 0,
          offsetX: -10,
        },
      },
      tooltip: {
        enabled: false,
      },
    }),
    [data],
  );

  return (
    <>
      <div className="flex items-center justify-center">
        <h4 className="text-[#232E3A] text-sm font-semibold">
          {t("Распределение отправленных работ")}
        </h4>
      </div>

      <div className="flex flex-col gap-[6px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span
              style={{
                borderColor: "#06D188",
              }}
              className="inline-block w-[8px] h-[8px] border-2 rounded-full "
            />
            <p className="text-xs font-normal text-[#232E3A]">
              {t("sentFromMvdToProc")}
            </p>
          </div>
          <p className="text-xs font-medium text-[#9747FF]">
            {formatAmount(data?.count_mvd_to_procroture ?? 0)}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span
              style={{
                borderColor: "#9747FF",
              }}
              className="inline-block w-[8px] h-[8px] border-2 rounded-full "
            />
            <p className="text-xs font-normal text-[#232E3A]">
              {t("sentFromProcToMvd")}
            </p>
          </div>

          <p className="text-xs font-medium text-[#9747FF]">
            {formatAmount(data?.procrature_to_mvd ?? 0)}
          </p>
        </div>
      </div>

      <div className="overflow-auto h-full">
        <ReactApexChart
          options={sentWorks as any}
          series={sentWorks.series}
          type="bar"
          height="40px"
          width="100%"
        />
      </div>
    </>
  );
}
