import request from "@/utils/axios";
import type { BaseParams, ListResponse } from "@/types";
import type { RequestType, FormValues } from "../types";

export async function getAllRequests(
  params: BaseParams & { search?: string; status?: string } = {},
): Promise<ListResponse<RequestType[]>> {
  const result: ListResponse<RequestType[]> = await request({
    url: "/account/users/",
    method: "get",
    params,
  });

  return result;
}

export async function getRequest(id: string): Promise<RequestType> {
  const result: RequestType = await request({
    url: `/account/users/${id}/`,
    method: "get",
  });

  return result;
}

export async function acceptRequest(
  pinfl: string,
  data: { status: "active" } & FormValues,
): Promise<{ status: "active" } & FormValues> {
  const result: { status: "active" } & FormValues = await request({
    url: `/account/activation-inspector/${pinfl}/`,
    method: "put",
    data,
  });

  return result;
}

export async function rejectRequest(id: number): Promise<void> {
  await request({
    url: `/account/confirm/${id}/`,
    method: "put",
    data: { status: "rejected", access: [] },
  });
}
