/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import chartBackground from "@/assets/chartBackground.png";
import formatAmount from "@/helpers/format-amount";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";

export default function RegionBarChart({
  mapDataQueryRegions,
  mapDataQueryDistricts,
  currentRegionId,
}: any): JSX.Element {
  const numMap = {
    1: 10,
    2: 100,
    3: 100,
    4: 1000,
    5: 10000,
    6: 100000,
    7: 1000000,
  };

  function nextClosestMax(datasource: any): number {
    let number = 0;

    if (Array.isArray(datasource)) {
      number = Math.max(...datasource.map((item) => item?.count));
    }

    const accumulator = (numMap as any)[number?.toString()?.length ?? 1];

    const nextClosest = Math.ceil(number / accumulator) * accumulator;

    const additional = accumulator / 2 < number ? accumulator : accumulator / 2;

    return nextClosest + additional;
  }

  const optionsBarChart = useMemo(
    () => ({
      series: [
        {
          data: mapDataQueryRegions?.region_counts?.map(
            (item: any) => item?.count,
          ),
        },
      ],
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        height: mapDataQueryRegions?.region_counts?.length
          ? mapDataQueryRegions.region_counts.length * 70
          : "100%",
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          barHeight: "70%",
          horizontal: true,
          columnWidth: "70%",
          dataLabels: {
            position: "top",
          },
          borderRadiusApplication: "end",
          colors: {
            // backgroundBarColors: ["#315EFB1A"],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 5,
          },
        },
      },
      stroke: {
        colors: ["transparent"],
        width: 2,
      },
      dataLabels: {
        enabled: true,
        textAnchor: "none",
        style: {
          colors: ["#000"],
          fontWeight: "bold",
        },
        formatter: (val: number) => ` ${val !== 0 ? formatAmount(val) : ""}`,
        offsetX: 20,
        offsetY: 0,
      },
      xaxis: {
        max: nextClosestMax(mapDataQueryRegions?.region_counts),
        min: 0,
        categories: mapDataQueryRegions?.region_counts?.map(
          (item: any) => item?.region_name,
        ),
        labels: {
          show: true,
          align: "left",
          style: {
            colors: ["#262626"],
            fontSize: "10px",
            fontWeight: 300,
          },
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
          align: "left",
          style: {
            colors: ["#262626"],
            fontSize: "12px",
            fontWeight: 500,
          },
          offsetY: 0,
          offsetX: -10,
        },
      },
      fill: {
        type: ["image", "gradient"],
        opacity: 1,
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
        image: {
          src: [chartBackground],
          width: undefined,
          height: undefined,
        },
      },
    }),
    [mapDataQueryRegions],
  );

  const optionsBarChart2 = useMemo(
    () => ({
      series: [
        {
          data: mapDataQueryDistricts?.districts?.map(
            (item: any) => item?.count,
          ),
        },
      ],
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          barHeight: "70%",
          horizontal: true,
          columnWidth: "70%",
          dataLabels: {
            position: "top",
          },
          borderRadiusApplication: "end",
          colors: {
            backgroundBarColors: ["#315EFB1A"],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 5,
          },
        },
      },
      stroke: {
        colors: ["transparent"],
        width: 2,
      },
      dataLabels: {
        enabled: true,
        textAnchor: "none",
        style: {
          colors: ["#000"],
          fontWeight: "bold",
        },
        formatter: (val: number) => ` ${val !== 0 ? formatAmount(val) : ""}`,
        offsetX: 20,
        offsetY: 0,
      },
      xaxis: {
        max: nextClosestMax(mapDataQueryDistricts?.districts),
        min: 0,
        categories: mapDataQueryDistricts?.districts?.map(
          (item: any) => item?.district_name,
        ),
        labels: {
          show: true,
          align: "left",
          style: {
            colors: ["#262626"],
            fontSize: "10px",
            fontWeight: 300,
          },
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
          align: "left",
          style: {
            colors: ["#262626"],
            fontSize: "12px",
            fontWeight: 500,
          },
          offsetY: 0,
          offsetX: -10,
        },
      },
      fill: {
        type: ["image", "gradient"],
        opacity: 1,
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
        image: {
          src: [chartBackground],
          width: undefined,
          height: undefined,
        },
      },
    }),
    [mapDataQueryDistricts],
  );

  return (
    <div className="w-full h-full overflow-hidden">
      {currentRegionId ? (
        <ReactApexChart
          options={optionsBarChart2 as any}
          series={optionsBarChart2.series}
          type="bar"
          height="100%"
          width="100%"
        />
      ) : (
        <ReactApexChart
          options={optionsBarChart as any}
          series={optionsBarChart.series}
          type="bar"
          height="100%"
          width="100%"
        />
      )}
    </div>
  );
}
