export const ADD_USER = "add_user";
export const CHANGE_USER = "change_user";
export const DELETE_USER = "delete_user";
export const VIEW_USER = "view_user";
export const VIEW_VIOLATION_TYPE = "view_violationtype";
export const VIEW_DOCUMENT_TYPE = "view_documenttype";

export const VIEW_EMPLOYEE_TYPE = "view_employeetype";
export const VIEW_LOG_ENTRY = "view_logentry";
export const VIEW_APP_SETTING = "view_appsetting";
export const VIEW_USER_SETTING = "view_usersetting";
export const VIEW_GROUP = "view_group";
export const VIEW_PERMISSION = "view_permission";
export const VIEW_DISTRICT = "view_district";
export const VIEW_REGION = "view_region";
export const VIEW_REQUEST_DATA = "view_requestdata";
export const VIEW_CONTENT_TYPE = "view_contenttype";
export const VIEW_EMPLOYEE = "view_employee";
export const VIEW_ORGANIZATION = "view_organization";
export const VIEW_SESSION = "view_session";
export const VIEW_ARTICLE = "view_article";
export const VIEW_COMMITTEE_MEMBERS = "view_committeemembers";
export const VIEW_EQUIPMENT_CONSUMPTIONS = "view_equipmentconsumptions";
export const VIEW_FILE = "view_file";
export const VIEW_REASON = "view_reason";
export const VIEW_STATUS = "view_status";
export const VIEW_STATUS_CHANGE_HISTORY = "view_statuschangehistory";
export const VIEW_VIOLATION = "view_violation";
export const VIEW_VIOLATION_RULE = "view_violationrule";
export const VIEW_WITNESSES = "view_witnesses";
export const VIEW_PERSON = "view_person";
export const VIEW_VIOLATION_ORGANIZATION = "view_violationorganization";
export const VIEW_EVENT = "view_event";
export const VIEW_WEB_HOOK_GROUP = "view_webhookgroup";
