import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDebounce } from "usehooks-ts";
import { useAuthContext } from "@/contexts";
import { Button, Layout, theme, message, App } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { regionsQuery } from "@/features/acts-list";
import ShowTotal from "@/components/show-total";
import MessageContent from "@/components/message-content";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { RequestsState, RequestType } from "../../types";
import { rejectRequest, getAllRequests } from "../../api";

const { Header, Content } = Layout;

export default function useRequestsState(): RequestsState {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const { modal } = App.useApp();

  const [{ page, pageSize }, setPagination] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });
  const [search, setSearch] = useState<string>("");

  const debouncedSearch = useDebounce<string>(search);
  const [messageApi, contextHolder] = message.useMessage();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const {
    data,
    isLoading,
    isFetching,
    isRefetching,
    isPreviousData,
    isPlaceholderData,
    error,
  } = useQuery({
    queryKey: ["registration-requests", { page, pageSize, debouncedSearch }],
    queryFn: async () => {
      const res = await getAllRequests({
        page,
        page_size: pageSize,
        search: debouncedSearch,
        status: "waiting",
      });
      return res;
    },
    keepPreviousData: true,
    placeholderData: { count: 0, next: null, previous: null, results: [] },
  });

  const { data: locations, error: locationsError } = useQuery({
    ...regionsQuery(),
    placeholderData: { count: 0, next: null, previous: null, results: [] },
  });

  const regions = useMemo(() => {
    let regs = locations?.results.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    regs ??= [];

    return regs;
  }, [locations?.results]);

  const { mutate, isLoading: isDeleting } = useMutation({
    mutationFn: rejectRequest,
    onSuccess: () => {
      void queryClient.invalidateQueries(["registration-requests"]);
    },
    onError: (_error: { data: { detail: string } }) => {
      void messageApi.error({
        key: "mutation-error",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("mutation-error");
            }}
          >
            {_error.data.detail}
          </MessageContent>
        ),
      });
    },
  });

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  const onPageChange: TableProps<RequestType>["onChange"] = (
    pagination,
    _filters,
    sorter,
    extra,
  ) => {
    setPagination({
      page: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 20,
    });
    console.log("params", pagination, _filters, sorter, extra);
  };

  const showDeleteModal = (record: RequestType): void => {
    modal.confirm({
      title: t("sure-reject-request"),
      icon: <ExclamationCircleFilled />,
      content: `${record.last_name ?? ""} ${record.first_name ?? ""} ${
        record.middle_name ?? ""
      }`,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      okButtonProps: { loading: isDeleting },
      onOk() {
        mutate(record.id);
      },
    });
  };

  const paginationProps = {
    defaultCurrent: 1,
    defaultPageSize: 10,
    total: data?.count,
    showSizeChanger: true,
    showTotal(total: number, range: [number, number]) {
      return <ShowTotal total={total} range={range} text="requests-shown" />;
    },
    onShowSizeChange(current: number, size: number): void {
      console.log(current, size);
    },
    locale: { items_per_page: "" },
  };

  const isTableLoading =
    isLoading ||
    isPreviousData ||
    isPlaceholderData ||
    isFetching ||
    isRefetching ||
    isDeleting;

  const columns: ColumnsType<RequestType> = useMemo(
    () => [
      {
        title: "№",
        render(_value, _record, index) {
          return index + 1;
        },
      },
      {
        title: t("fullname"),
        render(_value, record) {
          return `${record.last_name} ${record.first_name} ${
            record.middle_name ?? ""
          }`;
        },
        sorter: (a, b) => {
          const aWhole = `${a.last_name} ${a.first_name} ${
            a.middle_name ?? ""
          }`;
          const bWhole = `${b.last_name} ${b.first_name} ${
            b.middle_name ?? ""
          }`;
          return aWhole.localeCompare(bWhole);
        },
      },
      {
        title: t("dob"),
        dataIndex: "birth_date",
      },
      {
        title: t("passport"),
        dataIndex: "passport_series",
      },
      {
        title: t("pinfl"),
        dataIndex: "pinfl",
        sorter: (a, b) => a.pinfl.localeCompare(b.pinfl),
      },
      {
        title: t("job-title"),
        dataIndex: "position",
      },
      {
        title: t("region"),
        dataIndex: "region",
        render(value: number) {
          const foundRegion = regions?.find((region) => region.value === value);
          if (typeof foundRegion !== "undefined") {
            return foundRegion.label;
          }
          return "";
        },
      },
      {
        title: t("status"),
        dataIndex: "status",
        render(value) {
          return t(value);
        },
      },
      {
        title: t("action"),
        render(_value, record) {
          return (
            <div className="flex gap-4">
              <Link to={`/inspectors/registration-requests/${record.id}`}>
                <Button className="bg-[#D8F3DC] text-[#40916C]">
                  {t("see")}
                </Button>
              </Link>
              <Button
                danger
                onClick={() => {
                  showDeleteModal(record);
                }}
              >
                {t("reject")}
              </Button>
            </div>
          );
        },
      },
    ],
    [user.is_superuser, regions, t],
  );

  useEffect(() => {
    if (error !== null) {
      void messageApi.error({
        key: "registration-requests-error",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("registration-requests-error");
            }}
          >
            {/* @ts-expect-error error type is unknown but it will get Response type and object from axios */}
            {error?.statusText ?? t("error-fetching-data")}
          </MessageContent>
        ),
      });
    }

    if (locationsError !== null) {
      void messageApi.error({
        key: "registration-requests-error",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("registration-requests-error");
            }}
          >
            {/* @ts-expect-error error type is unknown but it will get Response type and object from axios */}
            {locationsError?.statusText ?? t("error-fetching-data")}
          </MessageContent>
        ),
      });
    }
  }, [error, locationsError, messageApi, t]);

  return {
    Header,
    Content,
    data,
    columns,
    colorBgContainer,
    paginationProps,
    isTableLoading,
    contextHolder,
    onPageChange,
    onSearchChange,
    t,
  };
}
