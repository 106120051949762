import type { BaseParams, ListResponse, Region } from "@/types";
import request from "@/utils/axios";
import dayjs from "dayjs";

export async function getRegions(
  params: BaseParams = {},
): Promise<ListResponse<Region[]>> {
  const result: ListResponse<Region[]> = await request({
    url: "/config/regions/",
    method: "get",
    params,
  });

  return result;
}

export async function getMapsRegionPolygons(
  params: BaseParams = {},
): Promise<ListResponse<any[]>> {
  const result: ListResponse<any[]> = await request({
    url: "/config/region_polygon/",
    method: "get",
  });

  return result;
}

export async function getMapsDistrictPolygons(
  params: BaseParams = {},
): Promise<ListResponse<any[]>> {
  const result: ListResponse<any[]> = await request({
    url: "/config/district_polygon/",
    method: "get",
    params,
  });

  return result;
}

export async function getMapDataRegion(
  params: BaseParams = {},
): Promise<ListResponse<any[]>> {
  const result: ListResponse<any[]> = await request({
    url: "/statistic-counts/map/",
    method: "get",
    params,
  });

  return result;
}

export async function getMapDataDistricts(
  params: BaseParams = {},
  regionId: any,
): Promise<ListResponse<any[]>> {
  const result: ListResponse<any[]> = await request({
    url: `/statistic-counts/map/${regionId}`,
    method: "get",
    params,
  });

  return result;
}

export async function getChartData(params: BaseParams = {}): Promise<any[]> {
  const result: ListResponse<any> = await request({
    url: "/statistic-counts/",
    method: "get",
    params,
  });

  return result;
}
